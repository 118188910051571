body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

#nprogress .bar {
  height: 3px !important;
}

@font-face {
  font-family: "CircularXX-Black";
  src: local("CircularXX-Black"),
    url("./assets/fonts/CircularXX-Black.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-BlackItalic";
  src: local("CircularXX-BlackItalic"),
    url("./assets/fonts/CircularXX-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Bold";
  src: local("CircularXX-Bold"),
    url("./assets/fonts/CircularXX-Bold.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-BoldItalic";
  src: local("CircularXX-BoldItalic"),
    url("./assets/fonts/CircularXX-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Book";
  src: local("CircularXX-Book"),
    url("./assets/fonts/CircularXX-Book.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-BookItalic";
  src: local("CircularXX-BookItalic"),
    url("./assets/fonts/CircularXX-BookItalic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-ExtraBlack";
  src: local("CircularXX-ExtraBlack"),
    url("./assets/fonts/CircularXX-ExtraBlack.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-ExtraBlackItalic";
  src: local("CircularXX-ExtraBlackItalic"),
    url("./assets/fonts/CircularXX-ExtraBlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Italic";
  src: local("CircularXX-Italic"),
    url("./assets/fonts/CircularXX-Italic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Light";
  src: local("CircularXX-Light"),
    url("./assets/fonts/CircularXX-Light.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-LightItalic";
  src: local("CircularXX-LightItalic"),
    url("./assets/fonts/CircularXX-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Medium";
  src: local("CircularXX-Medium"),
    url("./assets/fonts/CircularXX-Medium.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-MediumItalic";
  src: local("CircularXX-MediumItalic"),
    url("./assets/fonts/CircularXX-MediumItalic.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Regular";
  src: local("CircularXX-Regular"),
    url("./assets/fonts/CircularXX-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-Thin";
  src: local("CircularXX-Thin"),
    url("./assets/fonts/CircularXX-Thin.otf") format("opentype");
}

@font-face {
  font-family: "CircularXX-ThinItalic";
  src: local("CircularXX-ThinItalic"),
    url("./assets/fonts/CircularXX-ThinItalic.otf") format("opentype");
}
